import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDocumentTitle } from "../hooks";

const Background = styled.div`
  // fullscreen, content centered
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // font size grows with screen height
  font-size: calc(10px + 2vmin);

  // coloring
  background-color: #282c34;
  color: white;
`;

export function Hey({ name }) {
  return (
    <Background>
      <h1>Hey{name && ` ${name}`}</h1>
    </Background>
  );
}

export function HeyPage() {
  // TODO: Don't love having this in a separate file from Route declaration
  useDocumentTitle("Hey");
  const { name } = useParams();
  return <Hey name={name} />;
}
