import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { HeyPage } from "./Hey";

// provide mock Routers / top-down state here
export function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" children={<HeyPage />} />
        <Route path="/hey/:name?" children={<HeyPage />} />

        <Route path="*">Page Not Found</Route>
      </Switch>
    </div>
  );
}

// not meant to be tested
export function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}
