import { useEffect } from "react";

export function useDocumentTitle(title) {
  useEffect(() => {
    const oldTitle = document.title;
    document.title = title;
    return () => {
      document.title = oldTitle;
    };
  });
}
